import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom";
import { useUser } from './context/UserContext';

import Home from './components/Home/Home';
import Login from './components/Login/Login';
import Logout from './components/Logout/Logout';
import Profile from './components/Profile/Profile';
import SignIn from './components/SignIn/SignIn';
import Add from './components/Add/Add';
import StravaToken from './components/StravaToken/StravaToken';

type ProtectedProps = {
  condition: boolean | undefined;
  children: JSX.Element;
}

function Protected({ condition, children }: ProtectedProps) {
  return condition ? children : <Navigate to="/" />;
}

export default function AppRoutes() {

  return (
    <>
      <Routes>
        {/* General */}
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/add" element={<Add />} />
        <Route path="/strava_token" element={<StravaToken />} />

        {/* Auth */}
        <Route path="/signin" element={<SignIn />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />

        {/* Components */}
        <Route path="/profile" element={<Profile />} />

        {/* 404 */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  )
}
