import React from 'react'
import { Activity } from '../../types/Activity'

export default function RowName({activity}: {activity: Activity}) {
  if (activity.source === 'strava' && activity.sourceId) {
    return (
      <a href={`https://www.strava.com/activities/${activity.sourceId}`} target='_blank' style={{color: 'inherit'}}>
        {activity.title}
      </a>
    )
  }
  return <>{activity.title}</>
}
