import React, { useMemo } from 'react'
import { useUser } from '../../context/UserContext';
import Loading from '../Loading/Loading';
import './Profile.css';
import { Navigate } from 'react-router';
import { STRAVA_CLIENT_ID } from '../../config/strava';
import { Link } from 'react-router-dom';

export default function Profile() {

    const { loading, profile } = useUser();

    const connectStravaUrl = useMemo(() => {
        let currentHost = window.location.origin;
        if (!currentHost.includes('localhost')) {
            currentHost = "https://run.american-broomstick.com";
        }
        return `http://www.strava.com/oauth/authorize?client_id=${STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${currentHost}/strava_token&approval_prompt=force&scope=activity:read_all`;
    }, [window.location.origin, STRAVA_CLIENT_ID]);


    if (loading) return (<Loading />)

    if (!profile) return (<Navigate to="/home" />)



    return (
        <div className='profile' style={{
            position: 'relative',
            height: 'calc(100vh - 60px)',
        }}>
            <div className="container center-align" style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
            }}>
                {profile?.photoUrl && <>
                    <img src={profile.photoUrl} alt="" style={{
                        borderRadius: '50%',
                        width: '150px',
                        height: '150px',
                        objectFit: 'cover',
                    }} />
                </>}
                <h4>{profile.displayName}</h4>
                <hr style={{ width: '50%', marginTop: '50px', marginBottom: '50px' }} />
                {!profile.stravaAthleteId ? (
                    <>
                        <p>🔗 Connect with Strava to get started!</p>
                        <a href={connectStravaUrl}>
                            <button className='btn strava-orange'>Connect with Strava</button>
                        </a>
                    </>
                ) : (
                    <a href={connectStravaUrl}>
                        <button className='btn strava-orange'>Refresh data from Strava</button>
                    </a>
                )}
                {profile?.fetchedStravaActivities == "fetching" && <div>
                    <p>
                        Strava integration connected ✅
                    </p>
                    <p>
                        Importing activities from Strava. This may take a few moments. Sit tight and enjoy the process! 🚴‍♂️🏃‍♀️
                    </p>
                    <div className="progress" style={{ width: '70%', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className="indeterminate strava-orange"></div>
                    </div>
                </div>}
                {profile?.fetchedStravaActivities == "fetched" && <div>
                    <p>
                        Strava integration connected ✅
                    </p>
                    <p>
                        Activities imported ✅
                    </p>
                </div>}
                <hr style={{ width: '50%', marginTop: '50px', marginBottom: '50px' }} />
                <Link to="/logout">
                    <div className='btn red'>logout</div>
                </Link>
            </div>
        </div>
    )
}


// http://www.strava.com/oauth/authorize?client_id=[REPLACE_WITH_YOUR_CLIENT_ID]&response_type=code&redirect_uri=http://localhost/exchange_token&approval_prompt=force&scope=read