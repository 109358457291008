import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { Run } from '../../../types/Activity';
import { useUser } from '../../../context/UserContext';
import { db } from '../../../config/firebase';
import { addDoc, collection, Timestamp } from "@firebase/firestore";
import { useNavigate } from 'react-router';
import { paceToString } from '../../../utils/paceToString';

const convertToDateTimeLocalString = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export default function AddRun() {

  const { profile } = useUser();
  
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    distance: "",
    hh: "",
    mm: "",
    ss: "",
    notes: "",
    avghr: "",
    maxhr: "",
    maxpace: "",
    date: "",
  });

  useEffect(() => {
    const now = new Date();
    setFormData({...formData, date: convertToDateTimeLocalString(now)})
  }, []);

  const [duration, setDuration] = useState({
    display: "",
    inputValue: "",
    seconds: 0,
  });
  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Remove all the ":"
    const numbers = parseInt(value.replace(/:/g, ""));
    const hours = Math.floor(numbers / 10000);
    const minutes = Math.floor((numbers - hours * 10000) / 100);
    const seconds = numbers - hours * 10000 - minutes * 100;
    
    setDuration({
      inputValue: value,
      display: `${hours ? `${hours.toFixed(0)}:` : ''}${minutes.toFixed(0).padStart(2, '0')}:${seconds.toFixed(0).padStart(2, '0')}`,
      seconds: hours * 3600 + minutes * 60 + seconds
    });
  }

  const [maxPace, setMaxPace] = useState({
    display: "",
    inputValue: "",
    secondsPerKm: 0,
  });
  const handleMaxPaceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Remove all the ":"
    const numbers = parseInt(value.replace(/:/g, ""));
    const minutes = Math.floor(numbers / 100);
    const seconds = numbers - minutes * 100;
    
    setMaxPace({
      inputValue: value,
      display: `${minutes}:${seconds.toFixed(0).padStart(2, '0')}`,
      secondsPerKm: minutes * 60 + seconds
    });
  }

  const averagePace: number | undefined = useMemo(() => {
    if (!duration.seconds || !formData.distance) {
      return undefined;
    }
    console.log(`distance: ${formData.distance}, duration: ${duration.seconds}`)
    const pace = (duration.seconds) / (parseFloat(formData.distance));
    console.log(`pace [min/km]: ${pace}`)
    return pace
  }, [duration.seconds, formData.distance])

  if (!profile) {
    return <div>Loading...</div>
  }

  // Event handler for input changes
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!profile) return;

    // Make the run
    const run: Run = {
      id: '',
      uid: profile.uid,
      title: formData.name,
      date: Timestamp.fromDate(new Date(formData.date)),
      type: "run",
      notes: formData.notes,
      source: "manual",
      intensity: "fun",
      distance: {
        meters: parseFloat(formData.distance) * 1000,
      },
      duration: {
        seconds: duration.seconds,
      },
      heartRate: {
        max: parseInt(formData.maxhr),
        average: parseInt(formData.avghr),
      },
      pace: {
        max: {
          seconds: maxPace.secondsPerKm,
        },
        average: {
          seconds: averagePace ? averagePace : 0,
        },
      },
    }
    console.log(run);

    // Store the run in the database
    const docRef = await addDoc(collection(db, "activities"), run);
    console.log("Document written with ID: ", docRef.id);

    // Navigate home
    navigate('/home');
  }

  return (
    <div>
      <div className="container">
        <form onSubmit={handleSubmit}>

          {/* <div className="toggle-box">
            <div className="chip selected">easy</div>
            <div className="chip">steady</div>
            <div className="chip">tempo</div>
            <div className="chip">intervals</div>
            <div className="chip">long</div>
            <div className="chip">race</div>
          </div> */}

          <label>Name</label>
          <div className="row">
            <div className="col input-field input-simple">
              <input placeholder="activity name" id="name" type="text" style={{ fontSize: 20, fontWeight: 600 }} value={formData.name} onChange={handleInputChange} />
            </div>
          </div>

          <label>Distance [km]</label>
          <div className="row">
            <div className="col input-field input-simple">
              <input placeholder="distance" id="distance" type="number" inputMode="decimal" style={{ fontSize: 20, fontWeight: 600 }} value={formData.distance} onChange={handleInputChange} />
            </div>
          </div>

          <label>Duration [hh:mm:ss]</label>
          <div className="row">
            <div className="col input-field input-simple">
              <input placeholder="hh:mm:ss" id="hh" type="text" inputMode="numeric" style={{ fontSize: 20, fontWeight: 300 }} value={duration.display} onChange={handleDurationChange} />
            </div>
          </div>

          <label>Heart rate [bpm]</label>
          <div className="row">
            <div className="col s6 input-field input-simple">
              <input placeholder="average" id="avghr" type="number" inputMode="numeric" style={{ fontSize: 20, fontWeight: 300 }} value={formData.avghr} onChange={handleInputChange} />
            </div>
            <div className="col s6 input-field input-simple">
              <input placeholder="max" id="maxhr" type="number" inputMode="numeric" style={{ fontSize: 20, fontWeight: 300 }} value={formData.maxhr} onChange={handleInputChange} />
            </div>
          </div>

          <label>Pace [min/km]</label>
          <div className="row">
            <div className="col s6 input-field input-simple">
              <input placeholder="" id="avghr" type="text" disabled style={{ fontSize: 20, fontWeight: 300 }} value={paceToString(averagePace)} />
            </div>
            <div className="col s6 input-field input-simple">
              <input placeholder="max" id="maxpace" type="text" inputMode="numeric" style={{ fontSize: 20, fontWeight: 300 }} onChange={handleMaxPaceChange} value={maxPace.display} />
            </div>
          </div>

          <label>Notes</label>
          <div className="row">
            <div className="col input-field input-simple">
              <textarea placeholder="notes" id="notes" className="materialize-textarea" style={{ fontSize: 15 }} value={formData.notes} onChange={e => setFormData({ ...formData, notes: e.target.value })} />
            </div>
          </div>

          <label>Run date</label>
          <div className="row">
            <div className="col input-field input-simple">
              <input  id="date" type="datetime-local" value={formData.date} onChange={handleInputChange} />
            </div>
          </div>

          <div className="row">
            <div className="center-align">
              <button className="btn waves-effect waves-light blue1" type="submit" name="action" style={{textTransform: 'none'}}>add
                <i className="material-icons right">directions_run</i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div >
  )
}
