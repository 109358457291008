import React, { useEffect, useMemo, useState } from 'react'
import { collection, onSnapshot, query, where, orderBy, limit } from '@firebase/firestore';
import { useUser } from '../../context/UserContext'
import { db } from '../../config/firebase';
import { Activity, Ride, Intensity, Run } from '../../types/Activity';
import Activities from '../Activities/Activities';
import { Navigate, useNavigate } from 'react-router';
import ActivitiesTable from '../ActivitiesTable/ActivitiesTable';

/*
This is the home page.
*/

interface Percentages {
  easy: {
    seconds: number;
    activities: number;
    percentage: number;
  };
  hard: {
    seconds: number;
    activities: number;
    percentage: number;
  };
  total: {
    seconds: number;
  };
  message: String;
}

export default function Home() {

  const { loading, profile } = useUser();
  const navigate = useNavigate();

  const [activities, setActivities] = useState<Activity[]>([]);

  const percentages: Percentages = useMemo((): Percentages => {
    const values: Percentages = {
      easy: {
        seconds: 0,
        activities: 0,
        percentage: 0
      },
      hard: {
        seconds: 0,
        activities: 0,
        percentage: 0
      },
      total: {
        seconds: 0,
      },
      message: "",
    };

    const cuttOffDate = Date.now();
    // Add times
    activities.forEach(activity => {
      // Skip activities older than 2 weeks
      if (cuttOffDate - activity.date.toMillis() > 1209600000) return; // 2 * 7 * 24 * 60 * 60 * 1000

      let intensity: Intensity = 'fun';
      if (activity.type === 'run') {
        const run: Run = activity as Run;
        intensity = run.intensity;
      }
      else if (activity.type === 'ride') {
        const ride: Ride = activity as Ride;
        intensity = ride.intensity;
      }
      if (intensity === 'zone_2' || intensity === 'easy' || intensity === 'steady' || intensity === 'long') {
        values.easy.seconds += activity.duration.seconds;
        values.easy.activities++;
      }
      if (intensity === 'fun' || intensity === 'intervals' || intensity === 'tempo' || intensity === 'race') {
        values.hard.seconds += activity.duration.seconds;
        values.hard.activities++;
      }
    });

    // Calculate percentages
    values.total.seconds = values.easy.seconds + values.hard.seconds;
    values.easy.percentage = Math.round((values.easy.seconds / values.total.seconds) * 10000) / 100;
    values.hard.percentage = Math.round((values.hard.seconds / values.total.seconds) * 10000) / 100;

    // Calculate message
    if (values.easy.percentage > 80) {
      const allowance = Math.round((values.easy.seconds / 0.8 * 0.2 - values.hard.seconds) / 60);
      values.message = `Nice job going easy! You can let loose now for ${allowance} minutes 🚀`;
    } else {
      const allowance = Math.round((values.hard.seconds / 0.2 * 0.8 - values.easy.seconds) / 60);
      values.message = `Time to ease up! Go easy for ${allowance} minutes 🙏🏼`;
    }
    return values
  }, [activities]);

  useEffect(() => {
    if (!profile) return;
    const collectionRef = collection(db, `activities`);
    const q = query(
      collectionRef,
      where("uid", "==", profile.uid),
      orderBy("date", "desc"),
      limit(50));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setActivities(snapshot.docs.map(doc => {
        let activity = doc.data() as Activity;
        activity.id = doc.id;
        return activity;
      }));
    })
    return () => {
      unsubscribe();
    }
  }, [profile]);


  if (loading) return <div>Loading...</div>

  if (!profile) return (
    /* When the user is not logged, redirect them to signin page */
    <Navigate to="/signin" />
  )

  if (activities.length === 0) return (
    /* Display this content when user is logged in but has not completed any activities */
    <div className="center-align" style={{
      position: 'relative',
      height: 'calc(100vh - 60px)',
      width: '100%'
    }}>
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
      }}>
        <h3>Hey there! 👋🏼</h3>
        {!profile.stravaAthleteId ? (
          <div>
            <p>🔗 Connect with Strava to get started!</p>
            <a className="btn strava-orange" onClick={() => {
              navigate('/profile');
            }}>
              Connect with Strava
            </a>
          </div>
        ) : (
          <div>
            <p>You haven't completed any activities yet.
              <br />
              Go ahead and add your first activity!
            </p>
          </div>
        )}
      </div>
    </div>
  )

  if (!profile.stravaAthleteId) return (
    /* Display this content when user is logged in but has not connected their Strava account */
    <div className="container center-align">
      Hey there! 👋🏼 🔗 Connect with Strava to get started!
    </div>
  )


  return (
    /* Display this content when user is logged in */
    <div className="container center-align">

      {/* <div className="fixed-action-btn" style={{bottom: '45px', right: '24px'}}>
        <a className="btn-floating btn-large red" onClick={() => {
          navigate('/add');
        }}>
          <i className="large material-icons">add</i>
        </a>
      </div> */}

      {/* Percentages bar */}
      <div
        style={{
          height: '20px',
          width: '100%',
          backgroundColor: '#dc3545', // red
          borderRadius: '5px',
          marginTop: '20px',
        }}
      >
        <div
          style={{
            height: '100%',
            width: `${percentages.easy.percentage}%`,
            backgroundColor: '#0069d9', // blue
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px',
          }}
        />
      </div>
      <div className='center-align'>
        <h4>
          {percentages.easy.percentage}% easy
        </h4>
        {percentages.message}
      </div>
      <div className="row">
        <div className="col s12 m6">
          <div className="card blue-grey darken-1">
            <div className="card-content white-text">
              <span className="card-title">Easy</span>
              <p>Total time: {(percentages.easy.seconds / 3600).toFixed(2)} hours</p>
              <p>Total activities: {percentages.easy.activities}</p>
              <p>Percentage: {percentages.easy.percentage}%</p>
            </div>
          </div>
        </div>
        <div className="col s12 m6">
          <div className="card blue-grey darken-1">
            <div className="card-content white-text">
              <span className="card-title">Hard</span>
              <p>Total time: {(percentages.hard.seconds / 3600).toFixed(2)} hours</p>
              <p>Total activities: {percentages.hard.activities}</p>
              <p>Percentage: {percentages.hard.percentage}%</p>
            </div>
          </div>
        </div>
      </div>
      <ActivitiesTable activities={activities} />
    </div>
  )
}
