import React, { useMemo } from 'react'
import type { Ride as RideActivity } from '../../../types/Activity'
import { speedToString } from '../../../utils/speedToString';
import RowName from '../RowName';

export default function RideRow(props: { ride: RideActivity }) {

  const { ride } = props;

  const date = useMemo(() => {
    return ride.date.toDate().toLocaleString();
  }, [ride.date]);

  const distance = useMemo(() => {
    // max 2 decimal places
    return (ride.distance.meters / 1000).toFixed(2);
  }, [ride.distance.meters]);

  return (
    <tr className={`${ride.intensity}`} >
      <td>
        <div style={{ fontSize: 12 }}>🚴🏻‍♂️ <RowName activity={ride} /></div>
        <div className='small'>{ride.date.toDate().toDateString()}</div>
        <div className='small'>{ride.date.toDate().toLocaleTimeString()}</div>
      </td>
      <td>{distance}</td>
      <td>
        <div className='center'>
          {speedToString(ride.speed.average)}
        </div>
        <div className='center small'>
          {speedToString(ride.speed.max)}
        </div>
      </td>
      <td>
        <div className="center">
          {ride.heartRate.average}
        </div>
        <div className="center small">
          {ride.heartRate.max}
        </div>
      </td>
    </tr>
  )
}
