import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBYJBUFLxDo4tcC4g2nEA7qDue_8y9TGm4",
    authDomain: "runlog-265a2.firebaseapp.com",
    projectId: "runlog-265a2",
    storageBucket: "runlog-265a2.appspot.com",
    messagingSenderId: "747626353539",
    appId: "1:747626353539:web:c46a2560500f078fc81cb6",
    measurementId: "G-D50W5JYNHW"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const db = getFirestore(app);
export const storage = getStorage(app);