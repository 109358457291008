import { BrowserRouter } from "react-router-dom";
import Nav from './components/Nav/Nav';
import { UserProvider } from './context/UserContext';
import AppRoutes from './AppRoutes';

import './App.css';
import FootNav from "./components/FootNav/FootNav";

function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <div className="App">
          <header className="App-header">
            {/* <Nav /> */}
          </header>
          <main>
            <AppRoutes />
          </main>
          <footer>
            <FootNav />
          </footer>
        </div>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
