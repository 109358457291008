import React, { useMemo } from 'react'
import type { Run as RunActivity } from '../../../types/Activity'
import { paceToString } from '../../../utils/paceToString';
import RowName from '../RowName';

export default function RunRow(props: { run: RunActivity }) {

  const { run } = props;

  const date = useMemo(() => {
    return run.date.toDate().toLocaleString();
  }, [run.date]);

  const distance = useMemo(() => {
    // max 2 decimal places
    return (run.distance.meters / 1000).toFixed(2);
  }, [run.distance.meters]);

  return (
    <tr className={`${run.intensity}`} >
      <td>
        <div style={{ fontSize: 12 }}>🏃🏻‍♂️ <RowName activity={run} /></div>
        <div className='small'>{run.date.toDate().toDateString()}</div>
        <div className='small'>{run.date.toDate().toLocaleTimeString()}</div>
      </td>
      <td>{distance}</td>
      <td>
        <div className='center'>
          {paceToString(run.pace.average.seconds)}
        </div>
        <div className='center small'>
          {paceToString(run.pace.max.seconds)}
        </div>
      </td>
      <td>
        <div className="center">
          {run.heartRate.average}
        </div>
        <div className="center small">
          {run.heartRate.max}
        </div>
      </td>
    </tr>
  )
}
