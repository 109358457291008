import React from 'react'
import { Link } from 'react-router-dom'
import { useUser } from '../../context/UserContext';

export default function FootNav() {

  const { profile } = useUser();
  
  if (!profile) {
    return null;
  }

  return (
    <div className='foot-nav shade1'>
      <ul>
        <li><Link to="home"><i className="material-icons">directions_run</i></Link></li>
        <li><Link to="add"><i className="material-icons">add</i></Link></li>
        <li><Link to="profile"><i className="material-icons">person</i></Link></li>
      </ul>
    </div>
  )
}
