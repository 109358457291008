import React, { useState } from 'react'
import { ActivityType } from '../../types/Activity'
import Loading from '../Loading/Loading';
import { useUser } from '../../context/UserContext';
import { Navigate } from 'react-router';
import "./Add.css";
import AddRun from './AddRun/AddRun';
import AddCycle from './AddCycle/AddCycle';

export default function Add() {

  const { loading, authUser, profile } = useUser();

  const [activityType, setActivityType] = useState<ActivityType>("run");

  if (loading) return (<Loading />)
  if (!authUser) return (<Navigate to="/home" />)

  return (
    <div>
      <div className='toggle-box'>
        <div className={`toggle toggle-left ${activityType === "run" ? "selected" : ""}`} onClick={() => setActivityType("run")}>Run</div>
        <div className={`toggle toggle-right ${activityType === "ride" ? "selected" : ""}`} onClick={() => setActivityType("ride")}>Ride</div>
      </div>
      {activityType === "run" && <AddRun />}
      {activityType === "ride" && <AddCycle />}
    </div>
  )
}
