import React from 'react'
import { Activity } from '../../types/Activity'
import RunRow from './RunRow/RunRow';
import RideRow from './RideRow/RideRow';

export default function ActivitiesTable(props: { activities: Activity[] }) {

  const { activities } = props;

  return (
    <>
      <table>
        <thead>
          <th>Title</th>
          <th>Distance</th>
          <th>Pace</th>
          <th>HR</th>
        </thead>
        <tbody className='activities'>
          {activities.map((activity) => {
            switch(activity.type) {
              case 'run':
                return <RunRow run={activity} key={activity.id} />
              case 'ride':
                return <RideRow ride={activity} key={activity.id} />
              default:
                return <></>
            }            
          })}
        </tbody>
      </table>
    </>
  )
}
