import { Navigate, useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebase';
import StyledFirebaseAuth from './StyledFirebaseAuth';
import { GoogleAuthProvider, FacebookAuthProvider, EmailAuthProvider } from '@firebase/auth';
import { useUser } from '../../context/UserContext';

// Configure FirebaseUI.
const uiConfig: firebaseui.auth.Config = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // popupMode: true,
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/home',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        GoogleAuthProvider.PROVIDER_ID,
        // FacebookAuthProvider.PROVIDER_ID,
        // EmailAuthProvider.PROVIDER_ID,
    ],
};

function SignIn() {

    const { authUser, loading } = useUser();

    // If the user is already logged in, redirect to the home page
    console.log('authUser')
    console.log(authUser)
    if (!loading && authUser) {
        return <Navigate to="/home" />
    }

    return (
        <div className="container" style={{
            position: 'relative',
            height: '100vh',
            // justifyContent: 'center',
            // alignItems: 'center'
        }}>
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
            }}>
                <div className='center-align'>
                    <h3 className='center-align'>RunFlow</h3>
                    <p>Supercharge your running experience by connecting your Strava account.</p>
                    <p>Gain personalized insights, track your training load, and enhance your performance.
                    </p>
                </div>
                <br />
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} className='container' />
            </div>
        </div >
    );
}

export default SignIn