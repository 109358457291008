// UserContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../config/firebase'
import { doc , onSnapshot,} from '@firebase/firestore';
import { AuthUser, Profile } from '../types/Profile';

interface UserContextProps {
    authUser: AuthUser | null;
    profile: Profile | null;
    logout: () => Promise<void>;
    loading: boolean;
    authLoading: boolean;
}

const logout = async () => {
    await auth.signOut();
}

const initialContext: UserContextProps = {
    authUser: null,
    profile: null,
    logout,
    loading: true,
    authLoading: true,
};

const UserContext = createContext<UserContextProps>(initialContext);

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

interface UserProviderProps {
    children: React.ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {

    const [profile, setProfile] = useState<Profile | null>(initialContext.profile);
    const [authUser, setAuthUser] = useState<AuthUser | null>(initialContext.authUser);
    const [loading, setLoading] = useState(initialContext.loading);
    const [authLoading, setAuthLoading] = useState(initialContext.authLoading);

    // Auth state change listener
    useEffect(() => {
        setLoading(true);
        console.log("Auth state change listener");

        // Wait for auth state to be ready
        auth.authStateReady().then(() => {
            setAuthLoading(false);
        });

        // Listen for auth state changes
        const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
            console.log("Auth state changed");
            setAuthUser(authUser);
        });

        return () => {
            console.log("Unsubscribing from auth");
            unsubscribe();
        };
    }, []);


    // Profile state change listener
    useEffect(() => {
        // If auth state is not ready, do nothing
        if (authLoading) {
            return;
        }
        // If auth state is ready, grab the profile from firestore
        setLoading(true);
        console.log("Profile state change listener");
        if (authUser) {
            console.log("Auth user exists. Grabbing profile from firestore");
            const profileRef = doc(db, 'users', authUser.uid);
            const unsubscribeProfile = onSnapshot(profileRef, (profileDoc) => {
                console.log("Profile updated from firestore. Updating profile state");
                const profile = profileDoc.data() as Profile;
                setProfile(profile);
                setLoading(authLoading);
            });
            return () => {
                console.log("Unsubscribing from profile");
                unsubscribeProfile();
            }
        } else {
            console.log("Auth user does not exist. Setting profile to null");
            setProfile(null);
            setLoading(authLoading);
        }
    }, [authUser, authLoading]);

    const values: UserContextProps = {
        authUser: authUser || null,
        profile,
        logout,
        loading,
        authLoading,
    };

    return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};
