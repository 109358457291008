import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { db } from '../../config/firebase';
import { doc, updateDoc } from "@firebase/firestore";
import { useUser } from '../../context/UserContext';


export default function StravaToken() {

  const { profile } = useUser();
  const navigate = useNavigate();
  
  const [searchParams, setSearchParams] = useSearchParams();

  const [code, setCode] = useState<string | null>();
  const [scope, setScope] = useState<string | null>();

  useEffect(() => {
    setCode(searchParams.get("code"));
    setScope(searchParams.get("scope"));
  }, [searchParams]);

  useEffect(() => {
    if (!code || !scope || !profile) return;

    // Save the code in the user's profile
    const docRef = doc(db, "users", profile.uid);
    updateDoc(docRef, {
      stravaAuthorizationCode: code,
    })
      .then(() => {
        navigate("/home");
      })
      .catch((error) => {
        console.error("Error adding strava code to user's profile: ", error);
      });

  }, [code, scope, profile?.uid]);
  
  return (
    <div>
      Connecting to Strava...
    </div>
  )
}
